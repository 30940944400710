import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../../page/home";
import Contact from "../../page/contact";
import About from "../../page/about";

export const route = {
  home: "/",
  about: "/about",
    contact: "/contact",
};

const Routes = () => {
  return (
    <Switch>
        <Route exact path={route.home} component={Home} />
        <Route
            exact
            path={route.about}
            component={About}
        />
        <Route
            exact
            path={route.contact}
            component={Contact}
        />
      <Route
        component={Home}
      />
    </Switch>
  );
};

export default Routes;
