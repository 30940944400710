import React from "react";
import Layout from "../component/common/Layout";
import { route } from "../component/common/Routes";
import {Text, Heading} from "grommet/es6";

const About = () => {
  return (
    <Layout
      links={{
        prev: {
          url: route.home,
          title: "Home page",
        },
        next: {
          url: route.contact,
          title: "Contact page",
        },
      }}
    >
      <section>
          <Heading
              textAlign={'center'}
              pad={0}
              margin={{
                  top: 'none'
              }}
              size={'25px'}>
              About me
          </Heading>
        <Text as={'p'} margin={{
            bottom: 'none'
        }}>
            Full Stack Javascript Developer with over {new Date().getFullYear() - 2015} years experience in web and mobile application development.
        </Text>
      </section>
    </Layout>
  );
};

export default About;
